.features {

  @media (min-width: $width-mobile-album) {
    display: flex;
    flex-wrap: wrap;
    
  }

  @media (min-width: $width-tablet) {
    // padding-top: 120px;
    padding-bottom: 120px;
  }
}

.features__item {
  min-height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;

  @media (min-width: $width-mobile-album) {
    flex-basis: 50%;
    
  }

  @media (min-width: $width-tablet-album) {
    flex-basis: 33%;
    padding-top: 120px;
  }
}

.features__item--small {
  @media (min-width: $width-mobile-album) {
    flex-basis: 33%;
    
  }

  @media (min-width: $width-tablet) {
    flex-basis: 33%;
    
  }
}

.features__item--quarter {
  justify-content: flex-start;
  @media (min-width: $width-tablet-album) {
    flex-basis: 25%;
    padding-top: 120px;
  }
}

.features__img {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  width: 180px;
  align-self: center;
}

.features__img--small {
  

  @media (min-width: $width-mobile-album) {
    width: 175px;
    
  }
}

.features__img--round {
  min-height: auto;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.1);
  min-height: auto;
  margin-bottom: 42px;
}

.features__text {
  width: 209px;
}