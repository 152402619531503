.promo {
  background-color: $main-theme-color;
}

.promo--test {
  background: linear-gradient(to right, #65D9FF, #0274FE);
  padding-bottom: 50px;
  padding-top: 50px;
  
  @media (min-width: $width-desktop) {
    padding-top: 25px;
    
  }
}

.promo--hovering {
  
  position: absolute;
  bottom: 0;
  width: 100vw;
  left: calc(50% - 50vw);
  padding-bottom: 70px;
}