.main-nav {
  width: 100%;
}

.main-nav__list {
  display: flex;
  margin: 0;
  padding-left: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 46px;
  flex-wrap: wrap;

  @media (min-width: $width-tablet) {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

.main-nav__item {
  list-style: none;
  cursor: pointer;

  @media (min-width: $width-tablet) {
    // padding-left: 20px;
  }

  @media (min-width: $width-tablet-album) {
    padding-left: 40px;
  }
}

.main-nav__item--nopadding-left {
  padding-left: 0;

  @media (min-width: $width-tablet) {
    padding-left: 0;
  }

  @media (min-width: $width-tablet-album) {
    padding-left: 0;
  }
}

.main-nav__item--mobile {
  display: none;
  padding-left: 0;
  order: 1;
  flex-grow: 1;
  flex-basis: 100%;
  min-height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  // display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgray;

  @media (min-width: $width-tablet) {
    display: flex !important; // override for tablet inline js style rule
    padding-left: 30px;
    flex-grow: 0;
    flex-basis: auto;
    order: 0;
    border-top: 0;
  }
}

.main-nav__item--logo {
  @media (min-width: $width-tablet) {
    margin-right: auto;
  }
}

.main-nav__item--vr {
  display: none;

  @media (min-width: $width-tablet) {
    display: block;
    cursor: default;
    padding: 0;
    margin-left: 25px;
    content: "";
    width: 1px;
    height: 25px;
    // border-left: 1px solid #d3d3d3;
    background-color: #d3d3d3;
  }

  @media (min-width: $width-tablet-album) {
    padding: 0;
    margin-left: 45px;
    margin-right: 20px;
  }
}

.main-nav__item--secondary {
  

  @media (min-width: $width-tablet) {
    // padding-left: 15px;
  }

  @media (min-width: $width-tablet-album) {
    padding-left: 25px;
  }
}

.main-nav__item--burger {
  @media (min-width: $width-tablet) {
    display: none;
  }
}

.dropdown-first {
  margin-top: 10px;

  @media (min-width: $width-tablet) {
    margin-top: 0;
  }
}

.dropdown-last {
  padding-bottom: 0;

  @media (min-width: $width-tablet) {
    padding-bottom: 10px;
  }
}

.main-nav__link {
  font-size: $sm-text-mobile-font;
  line-height: $sm-text-mobile-line;
  font-weight: 400;
  color: $main-text-color;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;

  @media (min-width: $width-tablet-album) {
    font-size: $sm-text-desktop-font;
    line-height: $sm-text-desktop-line;
  }

  &:hover,
  &:focus,
  &:active {
    color: $main-theme-color;
  }
}

.main-nav__link--secondary {
  opacity: 0.5;
}
