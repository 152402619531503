.text {
  font-weight: 400;
  font-size: $text-mobile-font;
  line-height: $text-mobile-line;

  @media (min-width: $width-tablet) {
    font-size: $text-tablet-font;
    line-height: $text-tablet-line;
    
  }

  @media (min-width: $width-tablet-album) {
    font-size: $text-desktop-font;
    line-height: $text-desktop-line;
    
  }
}

.text--sm {
  font-size: $sm-text-mobile-font;
  line-height: $sm-text-mobile-line;

  @media (min-width: $width-tablet) {
    font-size: $sm-text-tablet-font;
    line-height: $sm-text-tablet-line;
    
  }

  @media (min-width: $width-tablet-album) {
    font-size: $sm-text-desktop-font;
    line-height: $sm-text-desktop-line;
    
  }
}

.text--xs {
  font-size: $xs-text-mobile-font;
  line-height: $xs-text-mobile-line;

  @media (min-width: $width-tablet) {
    font-size: $xs-text-tablet-font;
    line-height: $xs-text-tablet-line;
    
  }

  @media (min-width: $width-tablet-album) {
    font-size: $xs-text-desktop-font;
    line-height: $xs-text-desktop-line;
    
  }
}

.text--grey {
  color: $text-grey;
}

.text--green {
  color: #38ba6a;
}

.text--red {
  color: #ff0000;
}

.text--theme {
  color: $main-theme-color;
}

.text--link {
  color: $main-text-color;

  &:hover,
  &:focus,
  &:active {
    color: $main-theme-color;
  }

  &:visited {
    color: $text-grey;
  }
}

.text--center {
  text-align: center;
}

.text--center-mobile {
  text-align: center;

  @media (min-width: $width-tablet) {
    text-align: left;
    
  }
}

.text--white {
  color: $white;
}

.text--white-mobile {
  color: $white;

  @media (min-width: $width-mobile-album) {
    color: $main-text-color;
    
  }
}

.text--margin-top-0 {
  margin-top: 0;
}

.text--margin-top-5 {
  margin-top: 5em;
}

.text--margin-bottom-0 {
  margin-bottom: 0;
}

.text--margin-top-half {
  margin-top: 0.5em;
}

.text--margin-bottom-half {
  margin-bottom: 0.5em;
}

.text--margin-bottom-big {
  margin-bottom: 50px;
}

.text--qrcode {
  display: none;

  @media (min-width: $width-tablet-album) {
    display: block;
  }
}

.text--narrow {
  max-width: 550px;

  @media (min-width: $width-tablet-album) {
    max-width: 750px;
  }
}

.text--upper {
  text-transform: uppercase;
}