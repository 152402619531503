.page-header {
  padding: 10px;
  border-bottom: 1px solid #dee2ea88;
  
  @media (min-width: $width-tablet) {
    padding: $padding-mobile;
  }
}

.page-header--landing {
  border-bottom: none;
  padding-bottom: 15px;
  padding-top: 15px;
}

.page-header--mobile {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.page-header__wrapper {
  display: flex;
  max-width: 1110px;
  margin: 0 auto;
  justify-content: space-between;

  @media (min-width: $width-mobile-album) {
    
  }
}

.page-header__wrapper--center-mobile {
  justify-content: center;

  @media (min-width: $width-tablet-album) {
    justify-content: space-between;
  }
}

.page-header__lang {
  margin-right: 10px;
  align-self: center;
  opacity: .5;
  // border-left: 1px solid #d3d3d3;
}

.page-header__lang-landing {
  text-decoration: none;
  color: white;
  line-height: 25px;
  font-weight: normal;
  padding-left: 20px;

  @media (min-width: $width-tablet) {
    
    padding-left: 30px;
  }

  @media (min-width: $width-tablet-album) {
    padding-left: 40px;
  }
}
