.footer-nav {
  padding-top: 0;
  padding-bottom: 10px;

  @media (min-width: $width-mobile-album) {
    flex-grow: 1;
    // max-width: 346px;
  }

  @media (min-width: $width-tablet) {
    padding-top: 10px;
  }
}
.footer-nav__list {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-left: 0;
  flex-wrap: wrap;

  @media (min-width: $width-mobile-album) {
    max-width: 350px;
    padding-bottom: 30px;
  }

  @media (min-width: $width-tablet) {
    max-width: 450px;
    padding-bottom: 0;
  }

  @media (min-width: $width-tablet-album) {
    max-width: 540px;
  }
}

.footer-nav__item {
  list-style: none;
  flex-basis: 50%;

  @media (min-width: $width-mobile-album) {
    flex-basis: auto;
    min-width: 140px;
  }

  @media (min-width: $width-tablet-album) {
    min-width: 180px;
  }
}

.footer-nav__link {
  font-size: $xs-text-mobile-font;
  line-height: $xs-text-mobile-line;
  font-weight: 400;
  color: $main-text-color;
  text-decoration: none;

  @media (min-width: $width-tablet-album) {
    font-size: $xs-text-desktop-font;
    line-height: $xs-text-desktop-line;
  }

  &:hover,
  &:focus,
  &:active {
    color: $main-theme-color;
  }
}

.footer-nav__link--alternative {
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $main-text-color;
  }
}

// .footer-nav {
//   padding-top: 10px;
//   padding-bottom: 10px;

//   @media (min-width: $width-mobile-album) {
//     flex-grow: 1;
//     max-width: 346px;
//   }
// }
// .footer-nav__list {
//   display: flex;
//   justify-content: space-between;
//   margin: 0;
//   padding-left: 0;
// }

// .footer-nav__item {
//   list-style: none;
//   // padding-left: 5px;
//   // padding-right: 5px;

//   @media (min-width: $width-tablet-album) {
//     // padding-left: 35px;
//     // padding-right: 35px;
//   }
// }

// .footer-nav__link {
//   font-size: $xs-text-mobile-font;
//   line-height: $xs-text-mobile-line;
//   font-weight: 400;
//   color: $main-text-color;
//   text-decoration: none;

//   @media (min-width: $width-tablet-album) {
//     font-size: $xs-text-desktop-font;
//     line-height: $xs-text-desktop-line;
//   }

//   &:hover,
//   &:focus,
//   &:active {
//     color: $main-theme-color;
//   }
// }

// .footer-nav__link--alternative {
//   color: $white;

//   &:hover,
//   &:focus,
//   &:active {
//     color: $main-text-color;
//   }
// }