html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("https://fonts.gstatic.com/s/roboto/v18/7m8l7TlFO-S3VkhHuR0atyYE0-AqJ3nfInTTiDXDjU4.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("https://fonts.gstatic.com/s/roboto/v18/7m8l7TlFO-S3VkhHuR0at44P5ICox8Kq3LLUNMylGO4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap; }

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url("https://fonts.gstatic.com/s/roboto/v18/vSzulfKSK0LLjjfeaxcREhTbgVql8nDJpwnrE27mub0.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto Italic"), local("Roboto-Italic"), url("https://fonts.gstatic.com/s/roboto/v18/vPcynSL0qHq_6dX7lKVByfesZW2xOQ-xsNqO47m55DA.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap; }

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("https://fonts.gstatic.com/s/roboto/v18/t6Nd4cfPRhZP44Q5QAjcCyYE0-AqJ3nfInTTiDXDjU4.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("https://fonts.gstatic.com/s/roboto/v18/t6Nd4cfPRhZP44Q5QAjcC44P5ICox8Kq3LLUNMylGO4.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap; }

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("https://fonts.gstatic.com/s/roboto/v18/Pru33qjShpZSmG3z6VYwnRJtnKITppOI_IvcXXDNrsc.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("https://fonts.gstatic.com/s/roboto/v18/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap; }

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("https://fonts.gstatic.com/s/roboto/v18/Fcx7Wwv8OzT71A3E1XOAjvesZW2xOQ-xsNqO47m55DA.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("https://fonts.gstatic.com/s/roboto/v18/CWB0XYA8bzo0kSThX0UTuA.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap; }

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("https://fonts.gstatic.com/s/roboto/v18/97uahxiqZRoncBaCEI3aWxJtnKITppOI_IvcXXDNrsc.woff2") format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("https://fonts.gstatic.com/s/roboto/v18/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
  font-display: swap; }

body {
  font-family: "Roboto", "Arial", sans-serif;
  color: #031d5b;
  font-weight: 300;
  min-width: 320px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0; }

#button--login button {
  display: none; }

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }

.text--sm {
  font-size: 14px;
  line-height: 24px; }

.text--xs {
  font-size: 12px;
  line-height: 24px; }

.text--grey {
  color: #5b6c94; }

.text--green {
  color: #38ba6a; }

.text--red {
  color: #ff0000; }

.text--theme {
  color: #0070ff; }

.text--link {
  color: #031d5b; }
  .text--link:hover, .text--link:focus, .text--link:active {
    color: #0070ff; }
  .text--link:visited {
    color: #5b6c94; }

.text--center {
  text-align: center; }

.text--center-mobile {
  text-align: center; }

.text--white {
  color: #ffffff; }

.text--white-mobile {
  color: #ffffff; }

.text--margin-top-0 {
  margin-top: 0; }

.text--margin-top-5 {
  margin-top: 5em; }

.text--margin-bottom-0 {
  margin-bottom: 0; }

.text--margin-top-half {
  margin-top: 0.5em; }

.text--margin-bottom-half {
  margin-bottom: 0.5em; }

.text--margin-bottom-big {
  margin-bottom: 50px; }

.text--qrcode {
  display: none; }

.text--narrow {
  max-width: 550px; }

.text--upper {
  text-transform: uppercase; }

.page-header {
  padding: 10px;
  border-bottom: 1px solid #dee2ea88; }

.page-header--landing {
  border-bottom: none;
  padding-bottom: 15px;
  padding-top: 15px; }

.page-header--mobile {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px; }

.page-header__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1110px;
  margin: 0 auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.page-header__wrapper--center-mobile {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.page-header__lang {
  margin-right: 10px;
  -ms-flex-item-align: center;
      align-self: center;
  opacity: .5; }

.page-header__lang-landing {
  text-decoration: none;
  color: white;
  line-height: 25px;
  font-weight: normal;
  padding-left: 20px; }

.main-nav {
  width: 100%; }

.main-nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding-left: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 46px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.main-nav__item {
  list-style: none;
  cursor: pointer; }

.main-nav__item--nopadding-left {
  padding-left: 0; }

.main-nav__item--mobile {
  display: none;
  padding-left: 0;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  min-height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid lightgray; }

.main-nav__item--vr {
  display: none; }

.dropdown-first {
  margin-top: 10px; }

.dropdown-last {
  padding-bottom: 0; }

.main-nav__link {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #031d5b;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px; }
  .main-nav__link:hover, .main-nav__link:focus, .main-nav__link:active {
    color: #0070ff; }

.main-nav__link--secondary {
  opacity: 0.5; }

.logo__img {
  width: 98px;
  height: 19px; }

.logo__img--small {
  width: 27px;
  height: 35px; }

.logo__link {
  margin-top: 13px;
  display: block;
  margin-top: 5px;
  padding: 10px; }

.logo__burger {
  width: 25px;
  height: 25px;
  padding: 15px;
  position: relative; }

.logo__burger::before,
.logo__burger::after {
  position: absolute;
  content: '';
  width: 25px;
  height: 3px;
  top: 21px;
  border-radius: 3px;
  background-color: grey;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s; }

.logo__burger::after {
  top: 31px; }

.logo__burger--close::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 26px; }

.logo__burger--close::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 26px; }

.page-content {
  margin-bottom: auto; }

.page-content--index {
  overflow: hidden; }

.page-content--landing {
  background-image: linear-gradient(120deg, #65d9ff, #0274fe);
  background-size: 100% 600px;
  background-repeat: no-repeat; }

.page-content--landingTest {
  background: none; }

.page-content--landingTest::before {
  position: absolute;
  content: "";
  background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa), url("../img/lockboxes1_768.jpg");
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

.page-content--landingKeepass::before {
  background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa), url("../img/keepass_alternative_landing.jpg");
  background-position: center; }

.page-content--landing-small {
  background-size: 100% 200px; }

.page-content--flex-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.page-content__wrapper {
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }

.page-content__wrapper--landing {
  overflow: hidden; }

.page-content__wrapper--index {
  min-height: 388px;
  padding-top: 10px;
  padding-bottom: 30px; }

.page-content__background {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 568px;
  background-position-y: -140px;
  height: 517px;
  width: 100%;
  z-index: -1;
  left: 0;
  top: 0px; }

.page-content__background--qrcode {
  background-image: url("../img/qr-bg-mobile.svg");
  background-position-y: -110px; }

.page-content__background--business {
  background-image: url("../img/business-bg-mobile.svg"); }

.page-content__background--landing {
  z-index: 0;
  background-image: url("../img/landing-bg-mobile.svg");
  background-position-y: -185px;
  background-position-x: center; }

.page-content__background--pricing {
  width: 100%;
  right: 0;
  top: 0;
  height: 220px;
  background-image: url("../img/pricing-bg-desktop.svg"), linear-gradient(120deg, #65d9ff, #0274fe);
  background-size: 100% 400px;
  background-repeat: no-repeat; }

.qrblock {
  width: 247px;
  margin: 0 auto; }

.qrblock__code {
  width: 247px;
  height: 247px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 35px;
  margin-bottom: 40px;
  padding: 1px;
  -webkit-box-shadow: 0 16px 60px 0 rgba(188, 188, 188, 0.37);
          box-shadow: 0 16px 60px 0 rgba(188, 188, 188, 0.37); }

.qrblock__code canvas {
  width: 240px !important;
  height: 240px !important;
  padding: 5px;
  margin: 0 auto; }

.qrtap {
  position: relative; }

.qrtap::before {
  pointer-events: none;
  position: absolute;
  display: block;
  content: '';
  width: 247px;
  height: 247px;
  border-radius: 10px;
  padding: 1px;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url("../img/qrcode_tap_hand.svg");
  -webkit-animation-name: qrtabAnimation;
          animation-name: qrtabAnimation;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate; }

@-webkit-keyframes qrtabAnimation {
  0%,
  55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=0);
    opacity: 0;
    background-color: rgba(0, 0, 0, 0); }
  80%,
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6); } }

@keyframes qrtabAnimation {
  0%,
  55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=0);
    opacity: 0;
    background-color: rgba(0, 0, 0, 0); }
  80%,
  to {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6); } }

.heading--h1 {
  font-size: 22px;
  line-height: 35px; }

.heading--h2 {
  font-size: 18px;
  line-height: 35px; }

.heading--h3 {
  font-size: 18px;
  line-height: 35px; }

.heading--h4 {
  font-size: 16px;
  line-height: 33px; }

.heading--uppercase {
  text-transform: uppercase; }

.heading--big {
  font-size: 2.2em; }

.heading--vault {
  font-size: 22px;
  line-height: 35px; }

.heading--white {
  color: #ffffff; }

.heading--white-mobile {
  color: #ffffff; }

.heading--theme {
  color: #0070ff; }

.heading--center {
  text-align: center; }

.heading--center-mobile {
  text-align: center; }

.heading--margin-top-big {
  margin-top: 50px; }

.heading--margin-bottom-0 {
  margin-bottom: 0; }

.heading--margin-bottom-40 {
  margin-bottom: 40px; }

.heading--margin-top-0 {
  margin-top: 0; }

.heading__sup {
  font-size: 16px;
  line-height: 24px;
  text-transform: none; }

.content {
  padding-top: 20px; }

.content--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.content--flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.content--layered {
  background-color: #ffffff;
  border-radius: 26px;
  -webkit-box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
          box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
  padding: 20px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 342px; }

.content--margin-top-big {
  margin-top: 136px; }

.content--padding-top-big {
  padding-top: 136px; }

.content--left {
  margin-left: auto; }

.content__wrapper {
  padding: 20px; }

.content__wrapper--white {
  background-color: #ffffff;
  border-radius: 26px;
  -webkit-box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
          box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
  padding: 20px;
  margin-left: 15px;
  margin-right: 15px; }

.content__wrapper--no-margin {
  margin: 0; }

.content__wrapper--narrow {
  max-width: 940px; }

.content--padding-top-none {
  padding-top: 0; }

.content--overlayed {
  min-height: 800px; }

.content--overlayed-small {
  min-height: 400px; }

.content--hovering {
  position: absolute;
  background-color: #ffffff;
  border-radius: 26px;
  -webkit-box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
          box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
  padding: 20px;
  z-index: 10;
  margin-left: calc(50% - 155px);
  width: 270px; }

.content--filler {
  min-height: 150px; }

.content__separator {
  width: 100%;
  height: 1px;
  background-color: #d3d3d399; }

.features__item {
  min-height: 270px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 40px; }

.features__item--quarter {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.features__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 120px;
  width: 180px;
  -ms-flex-item-align: center;
      align-self: center; }

.features__img--round {
  min-height: auto;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.1);
          box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.1);
  min-height: auto;
  margin-bottom: 42px; }

.features__text {
  width: 209px; }

.button {
  width: 175px;
  min-width: 175px;
  height: 42px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.1s;
  transition: all 0.1s; }

.button--common {
  border: 2px solid #0070ff;
  background-color: #ffffff;
  color: #0070ff; }

.button--common:hover,
.button--common:focus {
  background-color: #0070ff;
  color: #ffffff; }

.button--common:active {
  background-color: #0070ff;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 6px 0 rgba(87, 171, 255, 0.5);
          box-shadow: 0 2px 6px 0 rgba(87, 171, 255, 0.5); }

.button--alternative {
  border: 2px solid #ffffff;
  background-color: #0070ff;
  color: #ffffff; }

.button--alternative:hover,
.button--alternative:focus {
  background-color: #ffffff;
  color: #0070ff; }

.button--alternative:active {
  background-color: #ffffff;
  color: #0070ff;
  -webkit-box-shadow: 0 2px 6px 0 rgba(87, 171, 255, 0.5);
          box-shadow: 0 2px 6px 0 rgba(87, 171, 255, 0.5); }

.button--action {
  background-image: linear-gradient(260deg, #38ba6a, #76c551);
  color: #ffffff;
  opacity: 1; }

.button--action:hover,
.button--action:focus {
  background-image: linear-gradient(260deg, #24a660, #3a9d29);
  opacity: 1;
  -webkit-box-shadow: 0 2px 6px 0 rgba(68, 188, 101, 0.5);
          box-shadow: 0 2px 6px 0 rgba(68, 188, 101, 0.5); }

.button--action:active {
  background-image: linear-gradient(260deg, #24a660, #3a9d29);
  -webkit-box-shadow: 0 2px 6px 0 rgba(68, 188, 101, 0.5);
          box-shadow: 0 2px 6px 0 rgba(68, 188, 101, 0.5);
  opacity: 1; }

.button--margin-top-common {
  margin-top: 30px; }

.button--margin-top-big {
  margin-top: 60px; }

.button--margin-bottom-common {
  margin-bottom: 30px; }

.button--store {
  min-width: auto;
  border-radius: 7px;
  width: 149px;
  height: 43px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 2px solid #0070ff;
  margin: 0; }

.button--store-google {
  background-image: url("../img/btn-android.svg"); }

.button--store-apple {
  background-image: url("../img/btn-ios.svg"); }

.button--wide {
  width: 100%;
  max-width: 300px; }

.button--trial {
  width: 200px; }

.button--login {
  margin-top: 10px; }

.button--link {
  border: none; }

.button--no-border {
  border: 0; }

.button--low {
  height: 32px; }

.button--disabled {
  border: 2px solid #5b6c94;
  color: #5b6c94;
  cursor: auto; }

.img--features {
  margin: 0 auto;
  display: block;
  margin-right: 0; }

.img--features-o {
  margin-right: auto; }

.img--access {
  margin-right: 20px; }

.img--organize {
  margin-right: 7px; }

.img--share {
  margin-right: 3px; }

.img--encrypt {
  margin-right: 7px; }

.img--generate {
  margin-right: 3px; }

.img--features-center {
  margin-right: auto; }

.img--center {
  margin: 0 auto; }

.img__wrapper--security {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 189px;
  height: 154px;
  border-radius: 13.3px;
  -webkit-box-shadow: 0 0 21px 0 rgba(64, 0, 0, 0.06);
          box-shadow: 0 0 21px 0 rgba(64, 0, 0, 0.06); }

.img--validation {
  width: 45px;
  height: 45px;
  margin-left: calc(50% - 24px);
  margin-top: 50px; }

.img--checkmail {
  width: 106px;
  height: 92px;
  margin-left: calc(50% - 40px);
  margin-top: 20px;
  margin-bottom: 20px; }

.img--premium {
  margin-left: calc(50% - 60px); }

.page-footer {
  padding: 20px;
  position: relative; }

.page-footer--promoTest {
  min-height: 200px;
  padding: 0; }

.page-footer--alternative {
  background-color: #0070ff; }

.page-footer__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 1110px;
  margin: 0 auto; }

.page-footer__info {
  font-size: 12px;
  line-height: 24px;
  padding-top: 30px;
  padding-bottom: 10px; }

.social--center {
  margin: 50px auto; }

.social__list {
  list-style: none;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.social__item {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #031d5b;
  margin-left: 10px;
  margin-right: 10px; }
  .social__item:hover, .social__item:focus, .social__item:active {
    background-color: #0070ff; }

.social__item--alternative {
  background-color: #ffffff; }
  .social__item--alternative:hover, .social__item--alternative:focus, .social__item--alternative:active {
    background-color: #031d5b; }

.social__icon {
  fill: #ffffff; }

.social__icon--alternative {
  fill: #0070ff; }

.social__icon--fb {
  width: 11px;
  height: 22px;
  margin-left: 9px;
  margin-top: 4px; }

.social__icon--li {
  width: 17px;
  height: 17px;
  margin-left: 7px;
  margin-top: 6px; }

.social__icon--tw {
  width: 17px;
  height: 14px;
  margin-left: 7px;
  margin-top: 8px; }

.social__item a {
  font-size: 0; }

.footer-nav {
  padding-top: 0;
  padding-bottom: 10px; }

.footer-nav__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0;
  padding-left: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.footer-nav__item {
  list-style: none;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%; }

.footer-nav__link {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  color: #031d5b;
  text-decoration: none; }
  .footer-nav__link:hover, .footer-nav__link:focus, .footer-nav__link:active {
    color: #0070ff; }

.footer-nav__link--alternative {
  color: #ffffff; }
  .footer-nav__link--alternative:hover, .footer-nav__link--alternative:focus, .footer-nav__link--alternative:active {
    color: #031d5b; }

.popup {
  position: fixed;
  z-index: 10; }

.popup__close {
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  background-image: url("../img/icon-close.svg");
  top: -40px;
  right: 0;
  pointer-events: none; }

.popup__wrapper {
  background: transparent;
  position: relative; }

.popup__video {
  width: 100%;
  height: auto;
  background-color: #0070ff;
  z-index: 10; }

.popup--getstarted {
  position: absolute;
  margin-top: 550px;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 20px; }

.getstarted {
  max-width: 600px;
  counter-reset: stepCounter;
  padding-top: 20px;
  padding-bottom: 20px; }

.getstarted__list {
  padding: 0;
  list-style: none;
  counter-reset: item; }

.getstarted__item {
  padding-top: 100px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 20px;
  counter-increment: item; }

.getstarted__item::before {
  position: absolute;
  content: counters(item, ".");
  top: 0;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-image: linear-gradient(260deg, #38ba6a, #76c551);
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  font-weight: 400;
  text-align: center; }

.getstarted__item--first {
  background-image: url("../img/double-arrow-mobile.svg");
  background-position: 50% 34%;
  background-repeat: no-repeat; }

.getstarted__item--first::before {
  top: 35px; }

.getstarted__item--first::after {
  position: absolute;
  content: '';
  width: 175px;
  height: 50px;
  bottom: 34%;
  background-repeat: no-repeat;
  background-image: url("../img/double-brow-mobile.svg"); }

.getstarted__item--second {
  padding-top: 138px;
  background-image: url("../img/arrows-2-mobile.svg");
  background-position: 50% 20px;
  background-repeat: no-repeat;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.getstarted__item--second::before {
  top: 65px; }

.getstarted__item--third {
  padding-top: 138px;
  background-image: url("../img/arrows-3-mobile.svg");
  background-position: 50% 20px;
  background-repeat: no-repeat; }

.getstarted__item--third::before {
  top: 28px; }

.getstarted__store {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px; }

.getstarted__device {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 145px; }

.preview__element {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.preview__element {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 55px; }

.preview__carousel {
  background-color: #ddd;
  position: relative;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  border-radius: 10px; }

.preview__text {
  position: relative;
  min-height: 200px;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px; }

.preview__list {
  list-style: none;
  counter-reset: item;
  padding-left: 62px; }

.preview__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  counter-increment: item;
  min-height: 58px; }

.preview__item::before {
  position: absolute;
  content: counters(item, ".");
  top: 9px;
  left: -62px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #38ba6a;
  font-size: 18px;
  line-height: 35px;
  color: #38ba6a;
  font-weight: 400;
  text-align: center; }

.preview__item--active * {
  font-weight: bold; }

.preview__item--active::before {
  background-color: #38ba6a;
  color: #ffffff; }

.list {
  list-style: none;
  counter-reset: item;
  padding-left: 0; }

.list--through-1 {
  counter-reset: item 1; }

.list--through-2 {
  counter-reset: item 2; }

.list--through-3 {
  counter-reset: item 3; }

.list__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  counter-increment: item;
  min-height: 58px;
  padding-top: 40px;
  margin-bottom: 20px;
  margin-top: 20px; }

.list__item--ordered-big::before {
  position: absolute;
  content: counters(item, ".");
  top: 0;
  left: calc(50% - 17px);
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #38ba6a;
  font-size: 18px;
  line-height: 35px;
  color: #38ba6a;
  font-weight: 400;
  text-align: center; }

.list--narrow {
  max-width: 550px; }

.control {
  position: absolute;
  width: 150px;
  height: 20px;
  bottom: -35px;
  left: calc(50% - 75px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10; }

.control__input {
  display: none; }

.control__item {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer; }

.control__item::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  border-radius: 50%;
  background: #999;
  cursor: pointer; }

#control-block0-image1:checked ~ .control label[for="control-block0-image1"]::before,
#control-block0-image2:checked ~ .control label[for="control-block0-image2"]::before,
#control-block0-image3:checked ~ .control label[for="control-block0-image3"]::before,
#control-block1-image1:checked ~ .control label[for="control-block1-image1"]::before,
#control-block1-image2:checked ~ .control label[for="control-block1-image2"]::before,
#control-block1-image3:checked ~ .control label[for="control-block1-image3"]::before,
#control-block1-image4:checked ~ .control label[for="control-block1-image4"]::before,
#control-block2-image1:checked ~ .control label[for="control-block2-image1"]::before,
#control-block2-image2:checked ~ .control label[for="control-block2-image2"]::before,
#control-block2-image3:checked ~ .control label[for="control-block2-image3"]::before,
#control-block3-image1:checked ~ .control label[for="control-block3-image1"]::before,
#control-block3-image2:checked ~ .control label[for="control-block3-image2"]::before,
#control-block4-image1:checked ~ .control label[for="control-block4-image1"]::before,
#control-block4-image2:checked ~ .control label[for="control-block4-image2"]::before {
  background: #3cbb69; }

.slider {
  height: 100%;
  min-height: 222px;
  overflow: hidden;
  position: relative;
  width: auto;
  border-radius: inherit; }

.slider__slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
  -webkit-transition: opacity ease-in-out .5s, -webkit-transform ease-in-out .5s;
  transition: opacity ease-in-out .5s, -webkit-transform ease-in-out .5s;
  transition: transform ease-in-out .5s, opacity ease-in-out .5s;
  transition: transform ease-in-out .5s, opacity ease-in-out .5s, -webkit-transform ease-in-out .5s; }

.slider__img {
  border-radius: 10px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%; }

#control-block0-image1:checked ~ .slider > .slider__slide--1,
#control-block0-image2:checked ~ .slider > .slider__slide--2,
#control-block0-image3:checked ~ .slider > .slider__slide--3,
#control-block1-image1:checked ~ .slider > .slider__slide--1,
#control-block1-image2:checked ~ .slider > .slider__slide--2,
#control-block1-image3:checked ~ .slider > .slider__slide--3,
#control-block1-image4:checked ~ .slider > .slider__slide--4,
#control-block2-image1:checked ~ .slider > .slider__slide--1,
#control-block2-image2:checked ~ .slider > .slider__slide--2,
#control-block2-image3:checked ~ .slider > .slider__slide--3,
#control-block3-image1:checked ~ .slider > .slider__slide--1,
#control-block3-image2:checked ~ .slider > .slider__slide--2,
#control-block4-image1:checked ~ .slider > .slider__slide--1,
#control-block4-image2:checked ~ .slider > .slider__slide--2 {
  opacity: 1;
  z-index: 1; }

.tiles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.tiles__tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 334px;
  border-radius: 26px;
  -webkit-box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
          box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
  padding: 20px;
  margin-bottom: 20px; }

.tiles__bottom-element {
  margin-top: auto;
  margin-bottom: 2em; }

.tiles__upper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.faq {
  border-radius: 26px;
  -webkit-box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
          box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 60px; }

.faq__item:not(:last-of-type) {
  max-width: 1180px;
  border-bottom: 1px solid #979ec455; }

.faq__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 924px; }

.faq__content {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eaf1fa;
  display: none; }

.faq__content--show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.faq__dropdown {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.faq__dropdown--dropped {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.banner {
  position: relative;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, right top, from(#FFAA33), to(#F27A24));
  background-image: linear-gradient(to right, #FFAA33, #F27A24);
  -webkit-box-shadow: 0px 0px 40px #0000001A;
          box-shadow: 0px 0px 40px #0000001A; }

.banner__wrapper {
  max-width: 1110px;
  margin: 0 auto; }

.banner__text {
  margin-top: 14px;
  margin-bottom: 8px;
  margin-left: 15px; }

.banner__button {
  background-color: transparent;
  margin-bottom: 10px; }

.banner__button:hover,
.banner__button:focus,
.banner__button:active {
  color: #F27A24; }

.banner__img {
  display: none; }

.banner__close {
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.5; }

.promo {
  background-color: #0070ff; }

.promo--test {
  background: -webkit-gradient(linear, left top, right top, from(#65D9FF), to(#0274FE));
  background: linear-gradient(to right, #65D9FF, #0274FE);
  padding-bottom: 50px;
  padding-top: 50px; }

.promo--hovering {
  position: absolute;
  bottom: 0;
  width: 100vw;
  left: calc(50% - 50vw);
  padding-bottom: 70px; }

.form {
  max-width: 651px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
  overflow: hidden; }

.form--trial {
  max-width: none; }

.form__label {
  width: 100%;
  position: relative;
  border: 0;
  border-bottom: 1px solid #00000044;
  border-radius: unset; }

.form__label--wide {
  width: 100%;
  max-width: none; }

.form__input {
  background-color: transparent;
  border: 0;
  width: calc(100% - 20px);
  min-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #031d5b;
  outline-offset: -5px; }

.form__input--textarea {
  min-height: 30px;
  height: 30px;
  resize: none;
  padding-top: 14px;
  padding-bottom: 8px;
  font-family: inherit; }

.form__input--select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url(../img/icon-drop.svg);
  background-repeat: no-repeat;
  background-position: 94% 50%;
  background-size: 15px 15px;
  width: 100%; }

@-moz-document url-prefix() {
  .form__input--select {
    padding-top: 11px; } }

.form__input--white {
  color: #ffffff;
  border-bottom: 1px solid #ffffff88; }

.form__input--white:-webkit-autofill,
.form__input--white:-webkit-autofill:hover,
.form__input--white:-webkit-autofill:focus,
.form__input--white:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #0070ffdd inset;
  -webkit-text-fill-color: #ffffff !important; }

.form__input::-webkit-input-placeholder {
  color: #5b6c94; }

.form__input::-moz-placeholder {
  color: #5b6c94; }

.form__input:-ms-input-placeholder {
  color: #5b6c94; }

.form__input::-ms-input-placeholder {
  color: #5b6c94; }

.form__input::placeholder {
  color: #5b6c94; }

.form__input--white::-webkit-input-placeholder {
  color: #ffffff88; }

.form__input--white::-moz-placeholder {
  color: #ffffff88; }

.form__input--white:-ms-input-placeholder {
  color: #ffffff88; }

.form__input--white::-ms-input-placeholder {
  color: #ffffff88; }

.form__input--white::placeholder {
  color: #ffffff88; }

.overlay {
  position: fixed;
  content: '';
  z-index: 1;
  background-color: #0070ff99;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.cookies {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: #dddddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.cookies__element {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px; }

.cookies__text {
  color: #333333;
  line-height: 18px; }

.cookies__link {
  color: #0055cc;
  font-weight: 400; }

.cookies__accept {
  -ms-flex-item-align: center;
      align-self: center;
  margin-bottom: 15px;
  background-color: transparent; }

.cookies__accept--error {
  -webkit-animation-name: acceptError;
          animation-name: acceptError;
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -webkit-animation-duration: .8s;
          animation-duration: .8s; }

@-webkit-keyframes acceptError {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%; }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg); }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); } }

@keyframes acceptError {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%; }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg); }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); } }

.cookies__close {
  width: 56px;
  fill: #333333;
  padding-top: 10px;
  padding-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  cursor: pointer; }

@media (min-width: 568px) {
    .text--white-mobile {
      color: #031d5b; }
    .page-content__background {
      background-size: auto;
      background-position-y: -50px;
      background-position-x: right; }
    .page-content__background--qrcode {
      background-image: url("../img/qr-bg-desktop-upd.svg");
      background-size: 568px 568px;
      left: auto;
      right: -110px;
      background-position-y: -75px; }
    .page-content__background--business {
      background-image: url("../img/business-bg-desktop-wide.svg");
      height: 717px; }
    .page-content__background--landing {
      height: 717px;
      background-position-y: 0px;
      background-position-x: center; }
    .page-content__background--pricing {
      width: 100%;
      right: 0;
      top: 0;
      background-image: url("../img/pricing-bg-desktop.svg"), linear-gradient(120deg, #65d9ff, #0274fe);
      background-size: 100% 260px; }
    .qrblock {
      margin-right: 136px;
      width: 191px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 40px; }
    .qrblock__code {
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1;
      margin-bottom: 5px;
      width: 180px;
      height: 180px;
      padding-top: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 80px; }
    .qrblock__code canvas {
      width: 180px !important;
      height: 180px !important;
      padding: 0;
      border-radius: 10px; }
    .qrtap::before {
      width: 180px;
      height: 180px; }
    .heading--white-mobile {
      color: #031d5b; }
  .content--business {
    margin-bottom: 160px; }
  .content--landing {
    margin-bottom: 150px; }
    .content--layered {
      width: 480px;
      max-width: none;
      margin-left: calc(50% - 260px); }
    .content__wrapper--white {
      max-width: 940px; }
    .content--overlayed {
      min-height: 550px; }
    .content--overlayed-small {
      min-height: 300px; }
    .content--hovering {
      margin-left: calc(50% - 270px);
      top: 450px;
      width: 500px; }
  .content--hovering-small {
    top: 0; }
  .features {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .features__item {
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%; }
  .features__item--small {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%; }
  .features__img--small {
    width: 175px; }
    .page-footer__wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
  .social {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    margin-top: 1.2em; }
    .social__list {
      margin: 0; }
    .footer-nav {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
    .footer-nav__list {
      max-width: 350px;
      padding-bottom: 30px; }
    .footer-nav__item {
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
      min-width: 140px; }
    .popup__video {
      width: 480px;
      height: calc(480px * 0.56); }
    .popup--getstarted {
      margin-top: 680px; }
    .preview__carousel {
      max-width: 505px;
      margin-left: calc(50% - 253px); }
    .preview__carousel {
      max-width: 600px;
      margin-left: calc(50% - 300px); }
  .preview__content {
    width: 430px;
    margin-left: calc(50% - 215px); }
    .slider {
      min-height: 337px; }
    .slider__slide {
      width: 505px;
      height: 337px; }
    .slider__img {
      width: 100%;
      max-width: none; }
    .tiles__tile {
      margin-left: calc(50% - 167px); }
    .banner__wrapper {
      min-height: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .banner__text {
      text-align: left;
      margin-left: 25px;
      margin-top: 8px; }
    .banner__button {
      margin-right: 50px;
      margin-bottom: 0px; }
    .banner__close {
      top: calc(50% - 15px); }
    .form__label {
      max-width: 48%; }
    .form__label--wide {
      max-width: none; }
  .form__label--trial {
    width: 48%; }
    .cookies__element {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -ms-flex-line-pack: center;
          align-content: center; }
    .cookies__accept {
      margin-bottom: 0; } }

@media (min-width: 720px) {
    .text {
      font-size: 18px;
      line-height: 26px; }
    .text--sm {
      font-size: 15px;
      line-height: 25px; }
    .text--xs {
      font-size: 13px;
      line-height: 25px; }
    .text--center-mobile {
      text-align: left; }
    .page-header {
      padding: 20px; }
    .page-header__lang-landing {
      padding-left: 30px; }
    .main-nav__list {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
    .main-nav__item--nopadding-left {
      padding-left: 0; }
    .main-nav__item--mobile {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      padding-left: 30px;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0;
      border-top: 0; }
  .main-nav__item--logo {
    margin-right: auto; }
    .main-nav__item--vr {
      display: block;
      cursor: default;
      padding: 0;
      margin-left: 25px;
      content: "";
      width: 1px;
      height: 25px;
      background-color: #d3d3d3; }
  .main-nav__item--burger {
    display: none; }
    .dropdown-first {
      margin-top: 0; }
    .dropdown-last {
      padding-bottom: 10px; }
    .page-content--landingTest::before {
      height: 400px; }
  .page__content--flex {
    margin-left: 0; }
    .page-content__wrapper--index {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
    .page-content__background--business {
      background-image: url(../img/business-bg-desktop-wide.svg);
      background-size: 554px 454px;
      background-position-y: -50px;
      background-position-x: 130px;
      width: 854px;
      height: 854px;
      left: auto;
      right: -300px;
      top: 0; }
    .page-content__background--landing {
      background-image: url("../img/landing-bg-desktop.svg");
      left: -100px;
      right: auto; }
    .page-content__background--pricing {
      width: 100%;
      right: 0;
      top: 0;
      background-image: url("../img/pricing-bg-desktop.svg"), linear-gradient(120deg, #65d9ff, #0274fe);
      background-size: 100% 250px; }
    .heading--center-mobile {
      text-align: left; }
  .heading--promo-big {
    font-size: 36px;
    line-height: 46px;
    max-width: 940px; }
  .content--narrow {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    width: 310px; }
  .content--business {
    padding-top: 1px;
    margin-bottom: 0; }
  .content--landing {
    width: 380px; }
  .content--landingTest {
    padding-bottom: 20px; }
    .content--layered {
      background-color: transparent;
      -webkit-box-shadow: none;
              box-shadow: none;
      width: 380px;
      margin-left: 0; }
    .content--margin-top-big {
      margin-top: 0; }
    .content--padding-top-big {
      padding-top: 0; }
    .content__wrapper--white {
      margin-left: 15px;
      margin-right: 15px; }
    .content--padding-top-none {
      padding-top: 0; }
    .content--overlayed {
      min-height: 410px; }
    .content--hovering {
      margin-left: calc(50% - 355px);
      top: 330px;
      width: 650px;
      padding: 30px; }
  .content--hovering-landingForm {
    position: absolute;
    bottom: 0;
    width: 698px;
    left: calc(50% - 383px); }
  .content--hovering-small {
    top: 0; }
  .content__padding-bottom {
    padding-bottom: 300px; }
  .features {
    padding-bottom: 120px; }
  .features__item--small {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%; }
  .button--left {
    margin-left: 0; }
    .button--trial {
      width: 300px; }
    .img__wrapper--security {
      margin: 0 40px; }
    .img--validation {
      margin-top: 100px; }
    .img--premium {
      height: 120px;
      width: auto; }
    .footer-nav {
      padding-top: 10px; }
    .footer-nav__list {
      max-width: 450px;
      padding-bottom: 0; }
    .popup__video {
      width: 580px;
      height: calc(580px * 0.56); }
    .getstarted {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-top: 60px;
      padding-bottom: 60px; }
  .preview__element--tablet-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .list__item {
      padding-top: 0; }
    .list__item--ordered-big::before {
      top: 15px;
      left: -62px; }
    .slider {
      min-height: 400px; }
    .slider__slide {
      width: 600px;
      height: 400px;
      left: calc(50% - 300px); }
    .tiles__tile {
      max-width: 480px;
      margin-left: calc(50% - 260px); }
  .tiles__tile--wide {
    max-width: 480px;
    margin-left: calc(50% - 260px); }
    .faq__heading {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px; }
    .banner__text {
      margin-left: 15px; }
    .banner__img {
      display: block;
      margin-left: 15px; }
    .cookies {
      padding-top: 5px;
      padding-bottom: 5px; }
    .cookies__element {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .cookies__text {
      line-height: 20px; }
  .cookies__br {
    display: none; }
    .cookies__accept {
      margin-left: 20px; }
    .cookies__close {
      width: 106px; } }

@media (min-width: 720px) and (min-height: 600px) {
    .popup--getstarted {
      margin-top: 400px; } }

@media (min-width: 720px) and (min-height: 1200px) {
    .popup--getstarted {
      margin-top: 250px; } }

@media (min-width: 940px) {
    .text {
      font-size: 20px;
      line-height: 28px; }
    .text--sm {
      font-size: 16px;
      line-height: 26px; }
    .text--xs {
      font-size: 14px;
      line-height: 26px; }
    .text--qrcode {
      display: block; }
    .text--narrow {
      max-width: 750px; }
    .page-header__wrapper--center-mobile {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .page-header__lang-landing {
      padding-left: 40px; }
    .main-nav__item {
      padding-left: 40px; }
    .main-nav__item--nopadding-left {
      padding-left: 0; }
    .main-nav__item--vr {
      padding: 0;
      margin-left: 45px;
      margin-right: 20px; }
  .main-nav__item--secondary {
    padding-left: 25px; }
    .main-nav__link {
      font-size: 16px;
      line-height: 26px; }
    .page-content--landingTest::before {
      background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa), url("../img/lockboxes1_1024.jpg");
      height: 600px; }
    .page-content--landingKeepass::before {
      background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa), url("../img/keepass_alternative_landing.jpg"); }
    .page-content__wrapper--index {
      min-height: 600px; }
    .page-content__background {
      background-size: 100% 854px;
      background-position-y: -50px;
      background-position-x: right;
      width: 854px;
      height: 854px;
      left: auto;
      right: -280px;
      top: -100px; }
    .page-content__background--business {
      background-size: 854px 684px; }
    .page-content__background--landing {
      background-image: url("../img/landing-bg-desktop.svg");
      background-position-y: 180px;
      background-size: auto;
      left: 0;
      background-position-x: left; }
    .page-content__background--pricing {
      width: 100vw;
      right: auto;
      left: calc(50% - 100vw/2);
      top: 0;
      background-image: url("../img/pricing-bg-desktop.svg"), linear-gradient(120deg, #65d9ff, #0274fe);
      background-size: 100% 350px; }
    .qrblock {
      margin-right: 118px;
      width: 230px; }
    .qrblock__code {
      width: 230px;
      height: 230px;
      margin-left: auto;
      margin-right: 18px;
      margin-top: 122px; }
    .qrblock__code canvas {
      width: 230px !important;
      height: 230px !important; }
    .qrtap::before {
      width: 230px;
      height: 230px; }
    .heading--h1 {
      font-size: 28px;
      line-height: 24px; }
    .heading--h2 {
      font-size: 28px;
      line-height: 42px; }
    .heading--h3 {
      font-size: 24px;
      line-height: 38px; }
    .heading--h4 {
      font-size: 20px;
      line-height: 34px; }
    .heading--big {
      font-size: 2.2em; }
  .heading--promo-big {
    font-size: 48px;
    line-height: 58px; }
    .heading__sup {
      font-size: 20px;
      line-height: 28px; }
    .content {
      padding-top: 60px; }
  .content--narrow {
    width: 480px; }
  .content--business {
    width: 480px; }
  .content--landing {
    width: 480px; }
  .content--landingTest {
    padding-top: 90px; }
    .content--padding-top-big {
      padding-top: 0; }
    .content__wrapper--white {
      margin: auto; }
    .content--padding-top-none {
      padding-top: 0; }
    .content--overlayed {
      min-height: 330px; }
    .content--hovering {
      margin-left: calc(50% - 490px);
      top: 400px;
      width: 900px;
      padding: 40px; }
  .content--hovering-landingForm {
    width: 940px;
    left: calc(50% - 490px); }
  .content--hovering-small {
    top: 0; }
    .features__item {
      -ms-flex-preferred-size: 33%;
          flex-basis: 33%;
      padding-top: 120px; }
    .features__item--quarter {
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
      padding-top: 120px; }
    .img__wrapper--security {
      margin: 0 auto; }
    .page-footer__info {
      width: 100%; }
    .footer-nav__list {
      max-width: 540px; }
    .footer-nav__item {
      min-width: 180px; }
    .footer-nav__link {
      font-size: 14px;
      line-height: 26px; }
    .popup__video {
      width: 680px;
      height: calc(680px * 0.56); }
    .getstarted {
      max-width: 780px; }
    .preview__element {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      min-height: 514px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .preview__element {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      min-height: 514px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
  .preview__element--height-auto {
    min-height: auto; }
    .preview__carousel {
      width: 510px;
      margin-left: 40px; }
    .preview__text {
      width: 510px;
      margin-left: 40px; }
  .preview__content {
    margin-left: 0; }
    .list__item--ordered-big::before {
      top: 20px;
      left: -62px; }
    .list--narrow {
      max-width: 750px; }
    .tiles {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
    .tiles__tile {
      width: 28%;
      min-height: 492px;
      margin-left: 0; }
  .tiles__tile--wide {
    width: 45%;
    min-height: 492px;
    margin-left: 0; }
    .tiles__upper {
      min-height: 300px; }
  .tiles__bottom {
    min-height: 290px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .faq__heading {
      padding: 10px 90px; }
    .faq__content {
      padding: 10px 90px; }
  .banner__br {
    display: none; }
    .banner__img {
      margin-left: 25px; }
  .form__label--trial {
    width: 31%; }
    .form__input {
      font-size: 16px;
      line-height: 26px; }
    .cookies__element {
      max-width: 1110px;
      margin: 0 auto; }
    .cookies__text {
      line-height: 22px; }
    .cookies__accept {
      margin-left: 30px; } }

@media (min-width: 940px) and (max-height: 800px) {
    .popup--getstarted {
      margin-top: 550px; } }

@media (min-width: 1110px) {
    .page-content--landingTest::before {
      background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa), url("../img/lockboxes1.jpg"); }
    .page-content--landingKeepass::before {
      background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa), url("../img/keepass_alternative_landing1600.jpg"); }
  .content--narrow {
    width: 510px; }
  .content--business {
    width: 510px; }
  .content--landing {
    width: 535px; }
    .content--overlayed {
      min-height: 410px; }
    .popup__video {
      width: 880px;
      height: calc(880px * 0.56); }
    .popup--getstarted {
      margin-top: 450px; }
    .preview__carousel {
      width: 710px; }
    .preview__text {
      width: 710px; }
    .promo--test {
      padding-top: 25px; } }

/*# sourceMappingURL=style.css.map */
