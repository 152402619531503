.popup {
  position: fixed;
  // background-color: $white;
  z-index: 10;
  // background-color: rgba(0,85,204,.8);

}

.popup__close {
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  background-image: url('../img/icon-close.svg');
  top: -40px;
  right: 0;
  pointer-events: none;
}

.popup__wrapper {
  background: transparent;
  // overflow: auto;
  position: relative;
}

.popup__video {
  width: 100%;
  height: auto;
  background-color: $main-theme-color;
  z-index: 10;

  @media (min-width: $width-mobile-album) {
    width: 480px;
    height: calc(480px * 0.56);
  }

  @media (min-width: $width-tablet) {
    width: 580px;
    height: calc(580px * 0.56);
  }

  @media (min-width: $width-tablet-album) {
    width: 680px;
    height: calc(680px * 0.56);
  }

  @media (min-width: $width-desktop) {
    width: 880px;
    height: calc(880px * 0.56);
  }
}

// .popup__wrapper--video::before {
//   position: absolute;
//   content: '';
//   width: 78px;
//   height: 47px;
//   background-image: url('../img/icon-play-youtube.svg');
//   background-repeat: no-repeat;
//   top: 223px;
//   right: 395px;
//   pointer-events: none;
//   z-index: 0;
// }

.popup--getstarted {
  position: absolute;
  margin-top: 550px;
  background-color: $white;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: $width-mobile-album) {
    margin-top: 680px;
  }

  @media (min-width: $width-tablet) and (min-height: 600px) {
    margin-top: 400px;
  }

  @media (min-width: $width-tablet) and (min-height: 1200px) {
    margin-top: 250px;
  }

  @media (min-width: $width-tablet-album) and (max-height: 800px) {
    margin-top: 550px;
  }

  @media (min-width: $width-desktop) {
    margin-top: 450px;
  }
}