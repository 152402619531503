.qrblock {
  width: 247px;
  margin: 0 auto;

  @media (min-width: $width-mobile-album) {
    margin-right: 136px;
    width: 191px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  @media (min-width: $width-tablet-album) {
    margin-right: 118px;
    width: 230px;
  }
}

.qrblock__code {
  width: 247px;
  height: 247px;
  background-color: $white;
  border-radius: 10px;
  margin-top: 35px;
  margin-bottom: 40px;
  padding: 1px;
  box-shadow: 0 16px 60px 0 rgba(188, 188, 188, 0.37);

  @media (min-width: $width-mobile-album) {
    order: -1;
    margin-bottom: 5px;
    width: 180px;
    height: 180px;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
  }

  @media (min-width: $width-tablet-album) {
    width: 230px;
    height: 230px;
    margin-left: auto;
    margin-right: 18px;
    margin-top: 122px;
  }
}

.qrblock__code canvas {
  width: 240px !important;
  height: 240px !important;
  padding: 5px;
  margin: 0 auto;

  @media (min-width: $width-mobile-album) {
    width: 180px !important;
    height: 180px !important;
    padding: 0;
    border-radius: 10px;
  }

  @media (min-width: $width-tablet-album) {
    width: 230px !important;
    height: 230px !important;
  }
}

// qrcode touch animation

.qrtap {
  position: relative;
}

.qrtap::before {
  pointer-events: none;
  position: absolute;
  display: block;
  content: '';
  width: 247px;
  height: 247px;
  border-radius: 10px;
  padding: 1px;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url('../img/qrcode_tap_hand.svg');
  animation-name: qrtabAnimation;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-duration: 2.5s;
  animation-direction: alternate;

  @media (min-width: $width-mobile-album) {
    width: 180px;
    height: 180px;
  }

  @media (min-width: $width-tablet-album) {
    width: 230px;
    height: 230px;
  }
}

@keyframes qrtabAnimation {
  0%,
  55% {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=0);
      opacity: 0;
      background-color: rgba(0, 0, 0, 0);
  }

  80%,
  to {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100);
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.6);
  }
}