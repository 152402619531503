.page-content {
  margin-bottom: auto;
}

.page-content--index {
  overflow: hidden;
}

.page-content--landing {
  background-image: linear-gradient(120deg, #65d9ff, #0274fe);
  background-size: 100% 600px;
  background-repeat: no-repeat;
}

.page-content--landingTest {
  background: none;
}

.page-content--landingTest::before {
  position: absolute;
  content: "";
  background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa ), url('../img/lockboxes1_768.jpg');
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media (min-width: $width-mobile-album) {
    
  }

  @media (min-width: $width-tablet) {
    height: 400px;
  }

  @media (min-width: $width-tablet-album) {
    background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa ), url('../img/lockboxes1_1024.jpg');
    height: 600px;
  }

  @media (min-width: $width-desktop) {
    background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa ), url('../img/lockboxes1.jpg');

  }

}

.page-content--landingKeepass::before {
  background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa ), url('../img/keepass_alternative_landing.jpg');
  background-position: center;

  @media (min-width: $width-tablet-album) {
    background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa ), url('../img/keepass_alternative_landing.jpg');
  }

  @media (min-width: $width-desktop) {
    background-image: linear-gradient(120deg, #65d9ffaa, #0274feaa ), url('../img/keepass_alternative_landing1600.jpg');
  }
}

.page-content--landing-small {
  background-size: 100% 200px;
}

.page__content--flex {
  @media (min-width: $width-tablet) {
    margin-left: 0;

  }
}

.page-content--flex-center {
  justify-content: center;
}

.page-content__wrapper {
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.page-content__wrapper--landing {
  overflow: hidden;
}

.page-content__wrapper--index {
  min-height: 388px;
  padding-top: 10px;
  padding-bottom: 30px;

  @media (min-width: $width-tablet) {
    display: flex;
    flex-wrap: wrap;

  }

  @media (min-width: $width-tablet-album) {
    min-height: 600px;

  }
}

.page-content__background {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 568px;
  background-position-y: -140px;
  height: 517px;
  width: 100%;
  z-index: -1;
  left: 0;
  top: 0px;
  
  @media (min-width: $width-mobile-album) {
    background-size: auto;
    background-position-y: -50px;
    background-position-x: right;
  }
  
  @media (min-width: $width-tablet-album) {
    background-size: 100% 854px;
    background-position-y: -50px;
    background-position-x: right;
    width: 854px;
    height: 854px;
    left: auto;
    right: -280px;
    top: -100px;
  }
}

.page-content__background--qrcode {
  background-image: url('../img/qr-bg-mobile.svg');
  background-position-y: -110px;
  
  @media (min-width: $width-mobile-album) {
    background-image: url('../img/qr-bg-desktop-upd.svg');
    background-size: 568px 568px;
    left: auto;
    right: -110px;
    background-position-y: -75px;

  }
  
  @media (min-width: $width-tablet-album) {
  }
}

.page-content__background--business {
  background-image: url('../img/business-bg-mobile.svg');
  
  @media (min-width: $width-mobile-album) {
    background-image: url('../img/business-bg-desktop-wide.svg');
    height: 717px;
  }
  
  @media (min-width: $width-tablet) {
    background-image: url(../img/business-bg-desktop-wide.svg);
    background-size: 554px 454px;
    background-position-y: -50px;
    background-position-x: 130px;
    width: 854px;
    height: 854px;
    left: auto;
    right: -300px;
    top: 0;
  }

  @media (min-width: $width-tablet-album) {
    
    background-size: 854px 684px;
  }
}

.page-content__background--landing {
  z-index: 0;
  // background-size: auto;
  background-image: url('../img/landing-bg-mobile.svg');
  background-position-y: -185px;
  background-position-x: center;
  
  @media (min-width: $width-mobile-album) {
    height: 717px;
    background-position-y: 0px;
    background-position-x: center;
  }
  
  @media (min-width: $width-tablet) {
    background-image: url('../img/landing-bg-desktop.svg');
    left: -100px;
    right: auto;
  }
  
  @media (min-width: $width-tablet-album) {
    background-image: url('../img/landing-bg-desktop.svg');
    background-position-y: 180px;
    background-size: auto;
    left: 0;
    background-position-x: left;
  }
}

.page-content__background--pricing {
  width: 100%;
  right: 0;
  top: 0;
  height: 220px;
  background-image: url('../img/pricing-bg-desktop.svg'), linear-gradient(120deg, #65d9ff, #0274fe);
  background-size: 100% 400px;
  background-repeat: no-repeat;
  
  @media (min-width: $width-mobile-album) {
    width: 100%;
    right: 0;
    top: 0;
    background-image: url('../img/pricing-bg-desktop.svg'), linear-gradient(120deg, #65d9ff, #0274fe);
    background-size: 100% 260px;
  }
  
  @media (min-width: $width-tablet) {
    width: 100%;
    right: 0;
    top: 0;
    background-image: url('../img/pricing-bg-desktop.svg'), linear-gradient(120deg, #65d9ff, #0274fe);
    background-size: 100% 250px;
  }
  
  @media (min-width: $width-tablet-album) {
    width: 100vw;
    right: auto;
    left: calc(50% - 100vw/2);
    top: 0;
    background-image: url('../img/pricing-bg-desktop.svg'), linear-gradient(120deg, #65d9ff, #0274fe);
    background-size: 100% 350px;
  }
}