.faq {
  border-radius: 26px;
  box-shadow: 0 0 40px 0 rgba(64, 0, 0, 0.06);
  overflow: hidden;
  margin-top: 60px;
  margin-bottom: 60px;
}

.faq__item:not(:last-of-type) {
  max-width: 1180px;
  border-bottom: 1px solid #979ec455;
  // min-height: 126px;
}

.faq__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $padding-mobile;
  padding-right: $padding-mobile;
  max-width: 924px;

  @media (min-width: $width-tablet) {
    
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  }

  @media (min-width: $width-tablet-album) {
    padding: 10px 90px;
  }
}

.faq__content {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eaf1fa;
  display: none;
  
    @media (min-width: $width-tablet-album) {
      padding: 10px 90px;
    }
}

.faq__content--show {
  display: flex;
}

.faq__dropdown {
  transition-duration: 0.2s;
  transform: rotate(180deg);
}

.faq__dropdown--dropped {
  transform: rotate(0deg);
}