.page-footer {
  padding: $padding-mobile;
  position: relative;
}

.page-footer--promoTest {
  min-height: 200px;
  padding: 0;
}

.page-footer--alternative {
  background-color: $main-theme-color;
}

.page-footer__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1110px;
  margin: 0 auto;

  @media (min-width: $width-mobile-album) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.page-footer__info {
  font-size: $xs-text-mobile-font;
  line-height: $xs-text-mobile-line;
  padding-top: 30px;
  padding-bottom: 10px;

  @media (min-width: $width-tablet-album) {
    width: 100%;
  }
}