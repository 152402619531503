.overlay {
  position: fixed;
  content: '';
  z-index: 1;
  background-color: #0070ff99;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}