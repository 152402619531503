.banner {
  position: relative;
  // top: 0;
  width: 100%;
  // height: 100px;
  background-image: linear-gradient(to right, #FFAA33, #F27A24);
  box-shadow: 0px 0px 40px #0000001A;

  @media (min-width: $width-mobile-album) {
    // height: 60px;
    
  }
  
}

.banner__wrapper {
  max-width: $width-desktop;
  margin: 0 auto;
  
  @media (min-width: $width-mobile-album) {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
}

.banner__text {
  margin-top: 14px;
  margin-bottom: 8px;
  margin-left: 15px;
  // text-align: center;

  @media (min-width: $width-mobile-album) {
    text-align: left;
    margin-left: 25px;
    margin-top: 8px;
    
  }

  @media (min-width: $width-tablet) {
    margin-left: 15px;
    
  }
}

.banner__br {
  @media (min-width: $width-tablet-album) {
   display: none;
    
  }
}

.banner__button {
  background-color: transparent;
  margin-bottom: 10px;
  
  @media (min-width: $width-mobile-album) {
    margin-right: 50px;
    margin-bottom: 0px;
    
  }

}

.banner__button:hover,
.banner__button:focus,
.banner__button:active {
  color: #F27A24;
}

.banner__img {
  display: none;

  @media (min-width: $width-tablet) {
    display: block;
    margin-left: 15px;
  }

  @media (min-width: $width-tablet-album) {
    margin-left: 25px;
    
  }
}

.banner__close {
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.5;
  
  @media (min-width: $width-mobile-album) {
   top: calc(50% - 15px);
    
  }
}