.button {
  width: 175px;
  min-width: 175px;
  height: 42px;
  border-radius: 25px;
  text-decoration: none;
  font-size: $text-mobile-font;
  line-height: $text-mobile-line;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
  transition: all 0.1s;
}

.button--left {

  @media (min-width: $width-tablet) {
    margin-left: 0;
    
  }
}

.button--common {
  border: 2px solid $button-common-color;
  background-color: $white;
  color: $button-common-color;
}

.button--common:hover,
.button--common:focus {
  background-color: $button-common-color;
  color: $white;
}

.button--common:active {
  background-color: $button-common-color;
  color: $white;
  box-shadow: 0 2px 6px 0 rgba(87, 171, 255, 0.5);
}

.button--alternative {
  border: 2px solid $white;
  background-color: $main-theme-color;
  color: $white;
}

.button--alternative:hover,
.button--alternative:focus {
  background-color: $white;
  color: $button-common-color;
}

.button--alternative:active {
  background-color: $white;
  color: $button-common-color;
  box-shadow: 0 2px 6px 0 rgba(87, 171, 255, 0.5);
}

.button--action {
  background-image: linear-gradient(260deg, #38ba6a, #76c551);
  color: $white;
  opacity: 1;
}

.button--action:hover,
.button--action:focus {
  background-image: linear-gradient(260deg, #24a660, #3a9d29);
  opacity: 1;
  box-shadow: 0 2px 6px 0 rgba(68, 188, 101, 0.5);
}

.button--action:active {
  background-image: linear-gradient(260deg, #24a660, #3a9d29);
  box-shadow: 0 2px 6px 0 rgba(68, 188, 101, 0.5);
  opacity: 1;
}

.button--margin-top-common {
  margin-top: 30px;
}

.button--margin-top-big {
  margin-top: 60px;
}

.button--margin-bottom-common {
  margin-bottom: 30px;
}

.button--store {
  min-width: auto;
  border-radius: 7px;
  width: 149px;
  height: 43px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 2px solid $button-common-color;
  margin: 0;
}

.button--store-google {
  background-image: url('../img/btn-android.svg')
}

.button--store-apple {
  background-image: url('../img/btn-ios.svg')
}

.button--wide {
  width: 100%;
  max-width: 300px;

}

.button--trial {
  width: 200px;

  @media (min-width: $width-tablet) {
    width: 300px;
    
  }
}

.button--login {
  margin-top: 10px;
}

.button--link {
  border: none;
}

.button--no-border {
  border: 0;
}

.button--low {
  height: 32px;
}

.button--disabled {
  border: 2px solid $text-grey;
  // background-color: black;
  color: $text-grey;
  cursor: auto;
}